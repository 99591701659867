<template>
  <div class="container is-fluid">
    <page-header :entity="$EntitiesName.Import">
      <h1 class="title" data-test="sel-project-header">
        Import des sous projets OSCAR
      </h1>
      <template #actions>
        <button v-if="!isImporting && entitiesToDelete.length > 0" class="button is-danger" @click="deleteEntities">Supprimer</button>
        <button v-if="!isImporting" class="button is-warning" @click="isImporting = !isImporting">Importer</button>
        <button v-if="isImporting" class="button is-warning" @click="isImporting = !isImporting">Annuler</button>
      </template>
    </page-header>

    <datatable v-if="!isImporting" :fetch="fetchOscarSubProjects" ref="datatable" :entity="$EntitiesName.OscarSubProject" v-model="entitiesToDelete" enableDownload>
      <div slot="dsp" slot-scope="{ item }" title="DSP" :sortable="true">{{ item.dsp }}</div>
      <div slot="oiCode" slot-scope="{ item }" title="Code OI" :sortable="true">{{ item.oiCode }}</div>
      <div slot="oscarProjectName" slot-scope="{ item }" title="Projet nom" :sortable="true">{{ item.oscarProjectName }}</div>
      <div slot="marketType" slot-scope="{ item }" title="Type de marché" :sortable="true">{{ item.marketType }}</div>
      <div slot="internalIdPm" slot-scope="{ item }" title="Id interne PM" :sortable="true">{{ item.internalIdPm }}</div>
      <div slot="offerIdPm" slot-scope="{ item }" title="Id offre PM" :sortable="true">{{ item.offerIdPm }}</div>
      <div slot="ipeIdPm" slot-scope="{ item }" title="Id ipe PM" :sortable="true">{{ item.ipeIdPm }}</div>
      <div slot="city" slot-scope="{ item }" title="Commune PM" :sortable="true">{{ item.city }}</div>
      <div slot="nroName" slot-scope="{ item }" title="Nom NRO" :sortable="true">{{ item.nroName }}</div>
      <div slot="ipeIdNro" slot-scope="{ item }" title="Id ipe NRO" :sortable="true">{{ item.ipeIdNro }}</div>
      <div slot="oscarSubProjectName" slot-scope="{ item }" title="Nom du sous-projet" :sortable="true">{{ item.oscarSubProjectName }}</div>
      <div slot="oscarId" slot-scope="{ item }" title="Id sous-projet" :sortable="true">{{ item.oscarId }}</div>
      <div slot="status" slot-scope="{ item }" title="Etat" :sortable="true">{{ item.status }}</div>
      <div slot="arcgisSubProjectName" slot-scope="{ item }" title="Nom Arcgis" :sortable="true">{{ item.arcgisSubProjectName }}</div>
      <template v-for="(column, idx) in extraColumns">
        <div :key="'extra' + idx + extraColumns.length" :slot="column" slot-scope="{ item }" :title="column" nofilter class="has-text-right">
          {{ item.oscarColumnSubProjects && item.oscarColumnSubProjects.find(element => element.oscarColumnName === column).value | number(2) }}
        </div>
      </template>
    </datatable>
    
    <div v-if="isImporting" class="panel infos">
      <div class="panel-heading has-background-primary">Copier/Coller pour importer</div>
      <div class="panel-block is-block no-border">
        <handsontable :columns="columns" :import="importSubProjects" allowExtraColumns :extraColumnsValidation="oscarColumnKeys"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PaginationBuilder from '../../lib/data/Paginate';

export default {
  name: 'oscar_subprojects_import',
  data: function() {
    return {
      isImporting: false,
      entitiesToDelete: [],
      oscarColumns: [],
      columns: [
        {
          key: 'dsp',
          label: 'dsp',
          required: true,
        },
        {
          key: 'oiCode',
          label: 'oi_code',
          required: true,
        },
        {
          key: 'oscarProjectName',
          label: 'projet_nom',
          required: true,
        },
        {
          key: 'marketType',
          label: 'type_marche',
        },
        {
          key: 'internalIdPm',
          label: 'id_interne_axione_pm',
        },
        {
          key: 'offerIdPm',
          label: 'id_offre_pm',
        },
        {
          key: 'ipeIdPm',
          label: 'id_ipe_pm',
        },
        {
          key: 'city',
          label: 'commune_pm',
        },
        {
          key: 'nroName',
          label: 'nro_nom',
        },
        {
          key: 'pieIdNro',
          label: 'id_ipe_nro',
        },
        {
          key: 'oscarSubProjectName',
          label: 'sprojet_nom',
          required: true,
        },
        {
          key: 'oscarId',
          label: 'sprojet_id',
          required: true,
        },
        {
          key: 'status',
          label: 'sprojet_etat',
          required: true,
        },
        {
          key: 'arcgisSubProjectName',
          label: 'sprojet_arcgis_name',
        },
      ],
      extraColumns: []
    };
  },
  computed: {
    oscarColumnKeys() {
      return this.oscarColumns.length == 0 ? [] : this.oscarColumns.map(c => c.key)
    },
    selectedToUrl() {
      return this.entitiesToDelete.map(object => object.id).toString();
    },
  },
  mounted() {
    this.$Api.OscarColumn.fetchOscarColumnsAsList(data => this.oscarColumns = data)
  },
  methods: {
    importSubProjects(data) {
      return this.$Api.Import.importOscarSubProjects(data);
    },
    fetchOscarSubProjects(p) {
      const paginate = new PaginationBuilder(p);
      return axios.get(`/oscar-subprojects?${paginate.toString()}`).then(response => {
        const sprojects = response.data.content
        let extraColumns = {}

        sprojects.forEach(sproject => {
          sproject.oscarColumnSubProjects.forEach(oscarColumn => {
            extraColumns[oscarColumn.oscarColumnName] = true
          })
        })
        this.extraColumns = Object.keys(extraColumns)

        return response.data
      });
    },
    deleteEntities() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer ces éléments ?",
        () =>
          axios.delete(`/oscar-subprojects?selected=${this.selectedToUrl}`).then((res) => {
            if (res.data.success) {
              this.$refs.datatable.refresh()
              this.entitiesToDelete = []
            }
          })
      );
    },
  }
}
</script>

<style>

</style>
