var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$EntitiesName.Import},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_vm.isImporting && _vm.entitiesToDelete.length > 0)?_c('button',{staticClass:"button is-danger",on:{"click":_vm.deleteEntities}},[_vm._v("Supprimer")]):_vm._e(),(!_vm.isImporting)?_c('button',{staticClass:"button is-warning",on:{"click":function($event){_vm.isImporting = !_vm.isImporting}}},[_vm._v("Importer")]):_vm._e(),(_vm.isImporting)?_c('button',{staticClass:"button is-warning",on:{"click":function($event){_vm.isImporting = !_vm.isImporting}}},[_vm._v("Annuler")]):_vm._e()]},proxy:true}])},[_c('h1',{staticClass:"title",attrs:{"data-test":"sel-project-header"}},[_vm._v(" Import des sous projets OSCAR ")])]),(!_vm.isImporting)?_c('datatable',{ref:"datatable",attrs:{"fetch":_vm.fetchOscarSubProjects,"entity":_vm.$EntitiesName.OscarSubProject,"enableDownload":""},scopedSlots:_vm._u([{key:"dsp",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"DSP","sortable":true}},[_vm._v(_vm._s(item.dsp))])}},{key:"oiCode",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Code OI","sortable":true}},[_vm._v(_vm._s(item.oiCode))])}},{key:"oscarProjectName",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Projet nom","sortable":true}},[_vm._v(_vm._s(item.oscarProjectName))])}},{key:"marketType",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Type de marché","sortable":true}},[_vm._v(_vm._s(item.marketType))])}},{key:"internalIdPm",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Id interne PM","sortable":true}},[_vm._v(_vm._s(item.internalIdPm))])}},{key:"offerIdPm",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Id offre PM","sortable":true}},[_vm._v(_vm._s(item.offerIdPm))])}},{key:"ipeIdPm",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Id ipe PM","sortable":true}},[_vm._v(_vm._s(item.ipeIdPm))])}},{key:"city",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Commune PM","sortable":true}},[_vm._v(_vm._s(item.city))])}},{key:"nroName",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Nom NRO","sortable":true}},[_vm._v(_vm._s(item.nroName))])}},{key:"ipeIdNro",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Id ipe NRO","sortable":true}},[_vm._v(_vm._s(item.ipeIdNro))])}},{key:"oscarSubProjectName",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Nom du sous-projet","sortable":true}},[_vm._v(_vm._s(item.oscarSubProjectName))])}},{key:"oscarId",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Id sous-projet","sortable":true}},[_vm._v(_vm._s(item.oscarId))])}},{key:"status",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Etat","sortable":true}},[_vm._v(_vm._s(item.status))])}},{key:"arcgisSubProjectName",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Nom Arcgis","sortable":true}},[_vm._v(_vm._s(item.arcgisSubProjectName))])}}],null,false,1296283334),model:{value:(_vm.entitiesToDelete),callback:function ($$v) {_vm.entitiesToDelete=$$v},expression:"entitiesToDelete"}},[_vm._l((_vm.extraColumns),function(column,idx){return void 0})],2):_vm._e(),(_vm.isImporting)?_c('div',{staticClass:"panel infos"},[_c('div',{staticClass:"panel-heading has-background-primary"},[_vm._v("Copier/Coller pour importer")]),_c('div',{staticClass:"panel-block is-block no-border"},[_c('handsontable',{attrs:{"columns":_vm.columns,"import":_vm.importSubProjects,"allowExtraColumns":"","extraColumnsValidation":_vm.oscarColumnKeys}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }